<script>
import axios from "axios";
import Swal from "sweetalert2";
import {
  authFackMethods,
  authMethods,
  notificationMethods,
} from "@/state/helpers";
import { mapState } from "vuex";

import appConfig from "@/app.config";
import { helpers, required } from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";
import $ from "jquery";

export default {
  setup() {
    return { v$: useVuelidate() };
  },
  page: {
    title: "Login",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {},
  data() {
    return {
      username: "",
      password: "",
      form_captcha: false,
      lanjut_validasi: true,
      is_one_time: false,
      captcha: "",
      hitungan_1: Math.floor(Math.random() * 20) + 1,
      hitungan_2: Math.floor(Math.random() * 20) + 1,
      submitted: false,
      authError: null,
      tryingToLogIn: false,
      isAuthError: false,
      configuration: [],
      token_jadwal: this.$route.query.seb,
      ipAddress: null,
    };
  },
  validations: {
    username: {
      required: helpers.withMessage("Username is required", required),
    },
    password: {
      required: helpers.withMessage("Password is required", required),
    },
  },
  computed: {
    ...mapState("authfack", ["status"]),
    notification() {
      return this.$store ? this.$store.state.notification : null;
    },
  },
  async mounted() {
    await this.getIpAddress();
    // get konfigurasi
    let self = this;

    let config_login = {
      method: "get",
      url: process.env.VUE_APP_BACKEND_URL_VERSION + "referensi/konfig-login",
      headers: {
        Accept: "application/json",
      },
    };
    axios(config_login)
      .then(function (response) {
        let response_data = response.data;
        let response_data_fix = response_data.data;
        if (response_data.meta.code == 200) {
          self.configuration = response_data_fix.referensi;
          if (response_data_fix.referensi.CAPTCHA == "Tidak") {
            self.form_captcha = false;
          } else {
            self.form_captcha = true;
          }

          if (response_data_fix.referensi.ONE_TIME_LOGIN == "Tidak") {
            self.is_one_time = false;
          } else {
            self.is_one_time = true;
          }
        } else {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: response_data_fix.data.message,
          });
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  },
  methods: {
    ...authMethods,
    ...authFackMethods,
    ...notificationMethods,
    // Try to log the user in with the username
    // and password they provided.
    async getIpAddress() {
      let dataIp = await $.getJSON(
        "https://api.db-ip.com/v2/free/self",
        function (data) {
          return data;
        }
      );
      this.ipAddress = dataIp.ipAddress;
    },
    tryToLogIn() {
      let self = this;
      this.submitted = true;

      // stop here if form is invalid
      this.v$.$touch();

      if (this.v$.$invalid) {
        return;
      } else {
        Swal.fire({
          title: '<i class="fas fa-spinner fa-spin"></i>',
          text: "Loading...",
          showConfirmButton: false,
        });

        if (self.form_captcha) {
          let jumlah_captcha = self.hitungan_1 + self.hitungan_2;
          if (jumlah_captcha != self.captcha) {
            self.lanjut_validasi = false;
          } else {
            self.lanjut_validasi = true;
          }
        }

        if (self.lanjut_validasi == false) {
          Swal.fire({
            icon: "warning",
            title: "Opps",
            text: "Captcha Salah!!!",
            timer: 1000,
            timerProgressBar: true,
            showCancelButton: false,
            showConfirmButton: false,
          });
        } else {
          axios
            .post(
              process.env.VUE_APP_BACKEND_URL_VERSION +
                "auth/login-log-peserta",
              {
                username: this.username,
                password: this.password,
                ip_address: this.ipAddress,
                token_jadwal: this.token_jadwal,
              }
            )
            .then((response) => {
              let data_login = response.data.data;
              let timerInterval;
              Swal.fire({
                icon: "success",
                title: "Berhasil",
                text: "Anda akan diarahkan ke halaman dashboard segera",
                timer: 2000,
                timerProgressBar: true,
                showCancelButton: false,
                showConfirmButton: false,
                willClose: () => {
                  clearInterval(timerInterval);
                },
              }).then((result) => {
                /* Read more about handling dismissals below */
                if (result.dismiss === Swal.DismissReason.timer) {
                  self.$router.push({
                    name: "dashboard-assesmen-hc",
                    query: {
                      sso: data_login.data,
                    },
                  });
                }
              });
            })
            .catch((error) => {
              let message = error.response.data.data.error;
              if (message == "Session has been used") {
                Swal.fire({
                  icon: "error",
                  title: "Oops...",
                  text: "Akun anda telah login di perangkat lain, silahkan logout terlebih dahulu!",
                });
              } else if (message == "Account cant find") {
                Swal.fire({
                  icon: "error",
                  title: "Oops...",
                  text: "Periksa Kembali Username & Password Anda!",
                });
              } else if (message == "Something went wrong") {
                Swal.fire({
                  icon: "error",
                  title: "Oops...",
                  text: "Terjadi Kesalahan, Coba lagi nanti",
                });
              } else {
                Swal.fire({
                  icon: "error",
                  title: "Oops...",
                  text: "Akun tidak ditemukan, periksa kembali username & password anda",
                });
              }
            });
        }
      }
    },
  },
};
</script>

<template>
  <div>
    <div class="container-fluid p-0">
      <div class="row g-0">
        <div class="col-xl-9">
          <div class="auth-full-bg pt-lg-5 p-4">
            <div class="w-100">
              <div
                class="bg-overlay"
                v-bind:style="{
                  background: 'url(' + configuration.LOGIN_BACKGROUND + ')',
                  backgroundSize: 'cover',
                  backgroundRepeat: 'no repeat',
                  backgroundPosition: 'center',
                }"
              ></div>
            </div>
          </div>
        </div>
        <!-- end col -->

        <div class="col-xl-3">
          <div class="auth-full-page-content p-md-5 p-4">
            <div class="w-100">
              <div class="d-flex flex-column h-100">
                <div class="mb-5 mb-md-0">
                  <center>
                    <router-link to="/login" class="d-block auth-logo">
                      <!--                      <img-->
                      <!--                        :src="configuration.LOGO_FULL_DARK"-->
                      <!--                        v-bind:alt="configuration.APPLICATION_FULL_NAME"-->
                      <!--                        height="35"-->
                      <!--                        class="auth-logo-dark"-->
                      <!--                      />-->
                      <!--                      <img-->
                      <!--                        :src="configuration.LOGO_FULL_LIGHT"-->
                      <!--                        v-bind:alt="configuration.APPLICATION_FULL_NAME"-->
                      <!--                        height="35"-->
                      <!--                        class="auth-logo-light"-->
                      <!--                      />-->
                      <img
                        src="@/assets/images/logo_pln_caption.png"
                        height="150"
                      />
                    </router-link>
                  </center>
                </div>
                <div class="my-auto">
                  <div>
                    <h5 class="text-primary">Selamat Datang</h5>
                    <p class="text-muted">
                      Masuk dengan username dan password anda.
                    </p>
                  </div>
                  <b-alert
                    v-model="isAuthError"
                    variant="danger"
                    class="mt-3"
                    dismissible
                    >{{ authError }}</b-alert
                  >

                  <div
                    v-if="notification.message"
                    :class="'alert ' + notification.type"
                  >
                    {{ notification.message }}
                  </div>
                  <div class="mt-4">
                    <b-form @submit.prevent="tryToLogIn">
                      <div class="row">
                        <div class="col-lg-12">
                          <b-form-group
                            class="mb-3"
                            id="input-group-1"
                            label="Username"
                            label-for="input-1"
                          >
                            <b-form-input
                              id="input-1"
                              v-model="username"
                              type="text"
                              placeholder="Masukkan Username"
                              :class="{
                                'is-invalid': submitted && v$.username.$error,
                              }"
                            ></b-form-input>
                            <div
                              v-for="(item, index) in v$.username.$errors"
                              :key="index"
                              class="invalid-feedback"
                            >
                              <span v-if="item.$message">{{
                                item.$message
                              }}</span>
                            </div>
                          </b-form-group>
                        </div>

                        <div class="col-lg-12">
                          <b-form-group
                            class="mb-3"
                            id="input-group-2"
                            label="Password"
                            label-for="input-2"
                          >
                            <b-form-input
                              id="input-2"
                              v-model="password"
                              type="password"
                              placeholder="Masukkan Password"
                              :class="{
                                'is-invalid': submitted && v$.password.$error,
                              }"
                            ></b-form-input>
                            <div
                              v-if="submitted && v$.password.$error"
                              class="invalid-feedback"
                            >
                              <span v-if="v$.password.required.$message">{{
                                v$.password.required.$message
                              }}</span>
                            </div>
                          </b-form-group>
                        </div>

                        <div class="col-lg-3" v-if="form_captcha">
                          <b-form-group
                            class="mb-3"
                            id="input-group-3"
                            label="Captcha"
                            label-for="input-3"
                          >
                            <b-form-input
                              id="input-3"
                              type="text"
                              :placeholder="hitungan_1 + ' + ' + hitungan_2"
                              :disabled="true"
                            ></b-form-input>
                          </b-form-group>
                        </div>

                        <div class="col-lg-9" v-if="form_captcha">
                          <b-form-group
                            class="mb-3"
                            id="input-group-4"
                            label="&nbsp;"
                            label-for="input-4"
                          >
                            <b-form-input
                              id="input-4"
                              v-model="captcha"
                              type="number"
                              placeholder="Masukkan Captcha"
                              :required="true"
                            ></b-form-input>
                          </b-form-group>
                        </div>

                        <div class="col-lg-12">
                          <div class="mt-3 d-grid">
                            <b-button
                              type="submit"
                              variant="primary"
                              class="btn-block"
                              >Log In</b-button
                            >
                          </div>
                        </div>
                      </div>
                    </b-form>
                  </div>
                </div>

                <div class="mt-4 mt-md-5 text-center">
                  <p class="mb-0">
                    Copyright ©
                    {{ new Date().getFullYear() }}
                    {{ configuration.COPYRIGHT_COPORATION }}.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- end col -->
      </div>
      <!-- end row -->
    </div>
    <!-- end container-fluid -->
  </div>
</template>
